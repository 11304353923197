import { defineStore } from "pinia";
import axios from "axios";
import { getCurrentYear } from "@/lib/utils";

export const useUploadStore = defineStore("upload", {
  state: () => ({
    year: 2023,
    years: [2022, 2023],
    uploads: [],
    forms: [],
    exports: [],
    snackbar: { show: false, text: "", color: "" },
    currentYear: getCurrentYear(),
  }),
  getters: {
    selectedYear: (state) => state.year,
    allYears: (state) => state.years,
  },
  actions: {
    async loadUploads() {
      try {
        const { data: uploads } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/uploads?year=${this.year}`,
        });
        this.uploads = uploads;
      } catch (error) {
        this.snackbar = {
          show: true,
          text: "Erreur de chargement",
          color: "error",
        };
      }
    },
    async loadForms() {
      try {
        const { data: forms } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/forms?year=${this.year}`,
        });
        this.forms = forms;
      } catch (error) {
        this.snackbar = {
          show: true,
          text: "Erreur de chargement",
          color: "error",
        };
      }
    },
    async loadFormsAdmin() {
      try {
        const { data: forms } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/forms?year=${this.year}`,
        });
        this.forms = forms;
      } catch (error) {
        this.snackbar = {
          show: true,
          text: "Erreur de chargement",
          color: "error",
        };
      }
    },
    async loadExportsLength() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/uploads?year=${this.year}`,
        });
        this.exports = data;
      } catch (error) {
        this.snackbar = {
          show: true,
          text: "Erreur de chargement",
          color: "error",
        };
      }
    },
    setYear(year) {
      this.year = year;
      if (this.$router.currentRoute.value.name === "Uploads") {
        this.loadUploads();
      }
      if (this.$router.currentRoute.value.name === "Dashboard") {
        this.loadForms();
      }
      if (this.$router.currentRoute.value.name === "Export des données") {
        this.loadExportsLength();
      }
      if (this.$router.currentRoute.value.name === "Documents") {
        this.loadFormsAdmin();
      }
    },
    checkSuperAdminRights() {
      if (
        !this.$keycloak.hasResourceRole(
          "superadmin",
          process.env.VUE_APP_KC_CLIENT_ID
        )
      ) {
        this.$router.push({ name: "Dashboard" });
      }
    },
    checkFirstRedirect() {
      if (this.$router.currentRoute.value.name === "home") {
        if (
          this.$keycloak.hasResourceRole(
            "superadmin",
            process.env.VUE_APP_KC_CLIENT_ID
          )
        ) {
          this.$router.push({ name: "Documents" });
        } else {
          this.$router.push({ name: "Dashboard" });
        }
      }
    },
  },
});
