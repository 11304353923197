import { format, isBefore, isSameMonth, parse } from "date-fns";

function getCurrentYear() {
  const currentDate = new Date();
  const octoberDate = parse("10/01", "MM/dd", new Date());

  if (
    isBefore(currentDate, octoberDate) ||
    isSameMonth(currentDate, octoberDate)
  ) {
    return parseInt(format(currentDate, "yyyy")) - 1;
  } else {
    return parseInt(format(currentDate, "yyyy"));
  }
}
function decomposeAccents(encodedString) {
  const decodedString = decodeURIComponent(encodedString);
  const decomposedString = decodedString.normalize("NFD");
  const reencodedString = encodeURIComponent(decomposedString);
  return reencodedString;
}
export { getCurrentYear, decomposeAccents };
