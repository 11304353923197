<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field label="Rechercher..." v-model="search"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-radio-group inline v-model="employeeFilter" hide-details>
          <v-radio label="Tous les employés" value="all"></v-radio>
          <v-radio label="Un fichier chargé" value="uploaded"></v-radio>
          <v-radio label="Pas de fichier chargé" value="empty"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="d-flex align-center justify-end">
        <div>{{ filteredForms.length }}/{{ forms.length }}</div>
      </v-col>
    </v-row>
    <v-row v-if="!filteredForms.length" class="ml-1">
      <v-col>Aucun résultat à afficher...</v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(form, index) in filteredForms"
        :key="form._id"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card class="mx-auto text-center" elevation="2">
          <v-card-item>
            <div>
              <div class="text-caption">
                {{ form.idisa }}
              </div>
              <div class="text-h6 mb-1">
                {{ form.firstname }} {{ form.lastname }}
              </div>
              <div class="text-caption">
                {{ form.role }}
              </div>
              <div>
                <v-tooltip
                  :text="`Dernier fichier déposé le ${format(
                    parseISO(form.uploads[0].createdAt),
                    'dd.MM.yyyy'
                  )}`"
                  v-if="form.uploads.length"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon="mdi-check-decagram-outline"
                      color="success"
                      v-bind="props"
                      size="x-large"
                      variant="plain"
                    ></v-btn>
                  </template>
                </v-tooltip>
                <v-tooltip :text="`Aucun fichier déposé pour ${year}`" v-else>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      icon="mdi-alert-decagram-outline"
                      color="warning"
                      v-bind="props"
                      size="x-large"
                      variant="plain"
                    ></v-btn>
                  </template>
                </v-tooltip>
              </div>
              <div>
                Téléchargements
                <div>
                  <v-btn
                    variant="plain"
                    @click="
                      downloadFile(year, 'documents', form.filename, index)
                    "
                    :loading="downloading === `documents-${index}`"
                    :disabled="downloading !== null"
                    v-if="year !== 2022"
                  >
                    Document
                  </v-btn>
                  <span v-if="form.uploads.length">|</span>
                  <v-btn
                    variant="plain"
                    v-if="form.uploads.length"
                    @click="
                      downloadFile(
                        year,
                        'uploads',
                        form.uploads[0].filename,
                        index
                      )
                    "
                    :loading="downloading === `uploads-${index}`"
                    :disabled="downloading !== null"
                  >
                    Upload
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useUploadStore } from "@/store/upload";
import { format, parseISO } from "date-fns";
import axios from "axios";
import FileDownload from "js-file-download";
import { decomposeAccents } from "@/lib/utils";
const store = useUploadStore();
await store.loadForms();
const { forms, year } = storeToRefs(store);
let search = ref("");
let downloading = ref(null);
let employeeFilter = ref("all");
let snackbar = ref({
  show: false,
  text: "",
  color: "",
});
const filteredForms = computed(() => {
  return forms.value.filter((form) => {
    return (
      (form.firstname.toLowerCase().includes(search.value.toLowerCase()) ||
        form.lastname.toLowerCase().includes(search.value.toLowerCase()) ||
        form.idisa.toLowerCase().includes(search.value.toLowerCase())) &&
      (employeeFilter.value === "all" ||
        (employeeFilter.value === "uploaded" && form.uploads.length) ||
        (employeeFilter.value === "empty" && !form.uploads.length))
    );
  });
});
async function downloadFile(year, type, filename, index) {
  downloading.value = `${type}-${index}`;
  try {
    const { data } = await axios({
      method: "get",
      url: `${
        process.env.VUE_APP_API_URI
      }/files/${year}/${type}/${decomposeAccents(filename)}`,
      responseType: "arraybuffer",
    });
    FileDownload(data, filename);
  } catch (error) {
    snackbar.value = {
      show: true,
      text: "Erreur de chargement",
      color: "error",
    };
  } finally {
    downloading.value = null;
  }
}
</script>